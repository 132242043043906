<template>
  <div class="main">
    <div class="main-top">
      <img @click="goBack" src="@/assets/image/arrow-left.png" />
      <span>卡券专区</span>
    </div>
    <div class="content">
      <div class="content-herder">
        <div
          :class="[
            'content-herder-item',
            { 'content-herder-item-active': changeType === 1 }
          ]"
          @click="changeChangeType(1)"
        >
          <div>我的卡券</div>
          <span></span>
        </div>
        <div
          :class="[
            'content-herder-item',
            { 'content-herder-item-active': changeType === 2 }
          ]"
          @click="changeChangeType(2)"
        >
          <div>卡券记录</div>
          <span></span>
        </div>
      </div>
      <div v-if="changeType === 1">
        <div class="card-box">
          <div class="line-title">卡券列表</div>

          <div class="cars-list">
            <div
              class="cars-item"
              v-for="(item, index) in couponList"
              :key="index"
              @click="changeType = 2"
            >
              <img
                class="cars-item-img"
                :src="require(`@/assets/images/card${item.ID}.png`)"
              />
              <div class="cars-item-name">
                {{ item.Name }}X{{ item.Amount }}<br /><span>完成任务</span>
              </div>
            </div>
            <van-empty v-if="couponList.length === 0" description="暂无数据" />
          </div>
        </div>

        <div class="card-box">
          <div class="line-title">卡券说明</div>
          <p>
            1、 翻倍卡：使用翻倍卡
            可以获得你完成的商业调查2倍奖励（信用值加成除外），例如：您完成A调查获得基础奖励500金币，信用值加成50金币，如果使用翻倍卡，您将额外获得500金币。<br />
            翻倍卡获取途径：①、通过参与速调吧答卷“寻宝专区”，关卡随机获得。②、通过参与速调吧不定期的活动获得。
          </p>
          <p>
            2、
            抽奖卡：使用抽奖卡，可以参与速调吧推出的“速调吧幸运抽奖100%中奖”活动，可以随机获得抽奖奖励。<br />
            抽奖卡获取方法：①、通过参与速调吧答卷“寻宝专区”，关卡随机获得。②、通过参与速调吧不定期的活动获得。
          </p>
          <p>
            3、
            免审卡：使用免审卡，可以立即收到当前正在审核的微信红包订单，无需等待。<br />
            免审卡获取方法：①、通过参与速调吧答卷“寻宝专区”，关卡随机获得。②、通过参与速调吧不定期的活动获得。
          </p>

          速调吧不断推出各种类型的卡券，在速调吧答卷的同时也能获得更多的乐趣，精彩预告：即将推出“复活卡”，复活卡：使用复活卡，可以恢复答卷人工审核拒绝的金币和信用值。<br />

          <strong> 注意：本活动只在新版速调吧中推出</strong>
        </div>
      </div>
      <div v-if="changeType === 2">
        <div class="content-main" @scroll="handleScroll">
          <div class="content-item" v-for="(item, index) in list" :key="index">
            <div class="content-item-left">
              <div class="name-box">
                <div class="name">{{ item.CardTypeName }}</div>
                <div class="description">获取时间 {{ item.Dateline }}</div>
                <div class="time" v-if="item.UpdateTime">
                  使用时间 {{ item.UpdateTime }}
                </div>
              </div>
            </div>
            <div class="content-item-right">
              <div class="line-right-box">
                <img src="@/assets/image/couponZone.png" />
              </div>
              <div class="btns">
                <div
                  v-if="item.Used || item.CardType === 2 || item.CardType === 4"
                  class="btn btn1"
                >
                  已使用
                </div>

                <div
                  v-else-if="item.CardType === 1"
                  @click="changeOperate(item, index)"
                  class="btn btn2"
                >
                  去抽奖
                </div>
                <div
                  v-else
                  class="btn btn3"
                  @click="changeOperate(item, index)"
                >
                  去使用
                </div>
              </div>
            </div>
          </div>
          <div class="isBottom" v-if="list.length > 0">
            {{ isBottom ? "我也是有底线的" : "触底刷新更多~" }}
          </div>
          <van-empty v-if="list.length === 0" description="暂无数据" />
        </div>
      </div>
    </div>

    <div class="zhezhao" v-if="fragmentShow" @click="changeFragmentShow"></div>

    <div class="jl-box" v-if="fragmentShow && fragmentType === 1">
      <div class="jl-box-card">
        <img class="bgCard1" src="@/assets/images/bgCard1.png" />
        <img
          class="close"
          src="@/assets/images/reward/close.png"
          @click="changeFragmentShow"
        />
        <div class="identifier">编号{{ ProjectNumber }}</div>
        <div class="bgicon-box">
          <div class="bgIcon1-box">
            <img class="bgIcon1" src="@/assets/images/bgIcon1.png" />
            <span>+{{ Points }}</span>
          </div>
          <img class="bgicon2" src="@/assets/images/bgicon2.png" />
          <div class="bgIcon3-box">
            <img class="bgicon3" src="@/assets/images/bgicon3.png" />
            <span>+{{ Points * 2 }}</span>
            <div class="X2-box">
              <img class="X2" src="@/assets/images/X2.png" />
            </div>
          </div>
        </div>

        <div class="btn-have" @click="goUrl('/mobile/myAccount')">我知道了</div>

        <div class="jl-tips">可在「我的账户」中查看</div>
      </div>
    </div>

    <div class="jl-box" v-if="fragmentShow && fragmentType === 2">
      <div class="jl-box-card">
        <img class="bgCard1" src="@/assets/images/bgCard2.png" />
        <img
          class="close"
          src="@/assets/images/reward/close.png"
          @click="changeFragmentShow"
        />
        <div class="identifier">编号{{ OrderNumber }}</div>
        <div class="bgicon-box2">
          <img class="bgCard2-bg" src="@/assets/images/bgCard2-bg.png" />
        </div>

        <div class="btn-have" @click="goUrl('/mobile/exchangeList')">
          我知道了
        </div>

        <div class="jl-tips">可在「兑换记录」中查看</div>
      </div>
    </div>

    <div class="jl-box" v-if="fragmentShow && fragmentType === 3">
      <div class="jl-box-card">
        <img class="bgCard1" src="@/assets/images/bgCard3.png" />
        <img
          class="close"
          src="@/assets/images/reward/close.png"
          @click="changeFragmentShow"
        />
        <div class="identifier">编号{{ ProjectNumber }}</div>
        <div class="bgicon-box3">
          <img class="bgCard3-bg" src="@/assets/images/bgCard3-bg.png" />

          <div class="bgCard3-name">
            <span class="bgCard3-name-left" v-if="Points"
              >金币+{{ Points }}</span
            >
            <span v-if="CreditValue">信用值+{{ CreditValue }}</span>
          </div>
        </div>

        <div class="btn-have" @click="goUrl('/mobile/myCredit')">我知道了</div>

        <div class="jl-tips jl-tips2">可在「我的信用值/我的账户」中查看</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      changeType: 1,
      list: [],
      couponList: [],
      userid: null,
      page: 1,
      isBottom: false,
      flag: true,
      used: null,
      cardType: null,
      fragmentShow: false,
      fragmentType: 1,

      ProjectNumber: "",
      Points: "",
      OrderNumber: "",
      CreditValue: ""
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goUrl(path) {
      this.$router.push(path);
    },
    changeChangeType(type) {
      this.changeType = type;
    },
    async getList() {
      await this.$http
        .get(`/api/v1/Cards`, {
          params: {
            page: this.page,
            size: 20
          }
        })
        .then(res => {
          const response = JSON.parse(res.request.response);
          if (response.status === 200 && response.response) {
            if (response.response.data && response.response.data.length !== 0) {
              this.list = this.list.concat(response.response.data);
              console.log("this.list", this.list);
              this.flag = true;
              this.page++;
            } else {
              this.flag = false;
              this.isBottom = true;
            }
            if (response.response.data.length < 20) {
              this.flag = false;
              this.isBottom = true;
            }
          }
        });
    },
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 当滚动到底部时，距离顶部+自身高度 >= 总高度
      if (scrollTop + clientHeight >= scrollHeight) {
        // 触发刷新操作
        console.log("触底刷新");
        if (this.flag) {
          this.flag = false;
          console.log("触底刷新");
          this.getList();
        }
      }
    },
    async getList2() {
      const data = await this.$http.get("/api/v1/Card/Stats");
      let response = JSON.parse(data.request.response);
      if (response.status === 200 && response.response) {
        console.log("response", response);
        this.couponList = response.response;
      }
    },
    changeOperate(data, index) {
      console.log("data", data);
      switch (data.CardTypeName) {
        case "抽奖卡":
          this.$router.push("/mobile/turntable");
          break;
        case "翻倍卡":
          console.log("翻倍卡");
          this.setCards(data.ID, 1, index);
          break;
        case "免审卡":
          console.log("免审卡");
          this.setCards(data.ID, 2, index);
          break;
        case "复活卡":
          console.log("复活卡");
          this.setCards(data.ID, 3, index);
          break;
      }
    },
    changeFragmentShow() {
      this.fragmentShow = false;
      this.ProjectNumber = "";
      (this.Points = ""), (this.OrderNumber = ""), (this.CreditValue = "");
    },

    async setCards(id, type, index) {
      const data = await this.$http.post(`/api/v1/Cards/${id}`, null, {
        params: {
          id
        }
      });
      let response = JSON.parse(data.request.response);
      if (response.status === 200 && response.response) {
        console.log("response", response);
        this.fragmentType = type;
        this.fragmentShow = true;
        if (response.extend) {
          this.Points = response.extend.Points;
          this.ProjectNumber = response.extend.ProjectNumber;
          this.OrderNumber = response.extend.OrderNumber;
          this.CreditValue = response.extend.CreditValue;
          this.list[index].Used = true;
        }
      } else {
        this.$toast(response.msg);
      }
    }
  },
  async created() {
    if (localStorage.getItem("userid")) {
      this.userid = localStorage.getItem("userid");
    } else {
      this.$router.push("/mobile");
    }
    this.getList();
    this.getList2();
  }
};
</script>
<style lang="scss" scoped>
.main {
  min-height: calc(100vh - 40px);
  width: 100vw;
  background-color: #f8f8fa;
  position: relative;
  padding-top: 40px;
  .main-top {
    position: fixed;
    top: 0;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #232323;
    font-size: 16px;
    font-weight: bolder;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    background-color: #f8f8fa;
    z-index: 3;
    img {
      width: 20px;
      position: absolute;
      left: 0;
      margin-left: 15px;
    }
  }
  .card-box {
    width: 100%;
    background-color: #fff;
    width: calc(100% - 30px);
    margin-left: 15px;
    padding: 15px;
    box-sizing: border-box;
    margin-bottom: 20px;
    .line-title {
      font-size: 16px;
      font-weight: bolder;
      color: #232323;
      margin-bottom: 15px;
    }
    p {
      color: #3d3d3d;
      font-size: 14px;
      margin-bottom: 12px;
    }
  }
  .cars-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .cars-item {
      flex-shrink: 0;
      width: 214px;
      height: 104px;
      border-radius: 10px;
      margin-right: 22px;
      position: relative;
      cursor: pointer;
      margin-bottom: 20px;

      .cars-item-img {
        width: 100%;
        height: 104px;
      }

      .cars-item-name {
        position: absolute;
        color: #fff;
        font-size: 14px;
        top: 34px;
        left: 18px;
        cursor: pointer;

        span {
          font-size: 12px;
          margin-top: 3px;
        }
      }
    }
  }
  .content {
    min-height: calc(100vh - 40px);
    .content-herder {
      height: 50px;
      margin: 10px 0;
      display: flex;
      justify-content: space-between;
      padding: 0 15px;
      box-sizing: border-box;
      border-bottom: 1px solid #eaeaea;
      .content-herder-item {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #232323;
        font-size: 16px;
      }
      .content-herder-item-active {
        color: #ffa200;
        font-weight: bolder;
        position: relative;
        span {
          width: 39px;
          height: 2px;
          background: #ffa200;
          border-radius: 10px 10px 10px 10px;
          position: absolute;
          left: 50%;
          bottom: 0px;
          transform: translateX(-50%);
        }
      }
    }
    .content-main {
      height: calc(100vh - 130px);
      overflow: auto;
      width: 100%;
      padding: 0 15px;
      box-sizing: border-box;
      .content-item {
        margin-bottom: 10px;
        width: 100%;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .content-item-left {
          display: flex;
          align-items: center;
          padding: 16px 0 16px 16px;
          box-sizing: border-box;
          .name-box {
            word-break: break-all;
            .name {
              color: #232323;
              font-size: 14px;
              font-weight: bolder;
            }
            .time {
              color: #666;
              font-size: 12px;
              margin-top: 9px;
            }
            .description {
              color: #666;
              font-size: 12px;
              margin-top: 9px;
            }
          }
        }
        .content-item-right {
          margin-left: 10px;
          flex-shrink: 0;
          height: 100%;
          align-items: center;
          display: flex;
          overflow: hidden;
          .btns {
            margin: 0 20px;
            .btn {
              width: 64px;
              height: 24px;
              border-radius: 32px;
              border: 1px solid #aaaaaa;
              text-align: center;
              line-height: 24px;
              font-size: 14px;
            }
            .btn1 {
              color: #aaaaaa;
              border-color: #aaaaaa;
            }
            .btn2 {
              color: #3399ff;
              border-color: #3399ff;
            }
            .btn3 {
              color: #ff3636;
              border-color: #ff3636;
            }
          }
          .line-right-box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            align-items: center;
            img {
              height: 100%;
              width: auto;
              max-height: 110px;
            }
          }
        }
      }
    }
  }
}
.isBottom {
  text-align: center;
  margin: 20px 0;
}

.zhezhao {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000000;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
}

// .jl-box {
//   position: fixed;
//   z-index: 1000001;
//   width: 69.8vw;
//   height: 80vw;
//   top: 40%;
//   left: 50%;
//   transform: translateX(-50%) translateY(-50%);
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   .jl-box-card {
//     width: 100%;
//     height: 100%;
//   }

//   .identifier {
//     position: absolute;
//     top: 29vw;
//     left: 50%;
//     transform: translateX(-50%);
//     text-align: center;
//     color: #c23d11;
//     font-size: 3.2vw;
//   }

//   // background-color: #fff;
//   .bgCard1 {
//     width: 100%;
//     height: 100%;
//   }

//   .close {
//     position: absolute;
//     right: -5.2vw;
//     top: 4.6vw;
//     width: 5.2vw;
//     height: 5.2vw;
//     cursor: pointer;
//   }

//   .bgicon-box {
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: flex-end;
//     position: absolute;
//     top: 36vw;

//     .bgIcon1-box {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       color: #c23d11;
//       font-size: 3.6vw;
//       font-weight: bolder;
//     }

//     .bgIcon3-box {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       color: #c23d11;
//       font-size: 3.6vw;
//       font-weight: bolder;
//       position: relative;

//       .X2-box {
//         position: absolute;
//         top: -1.6vw;
//         right: -3vw;
//         width: 5.8vw;
//         height: 3.2vw;
//         background: linear-gradient(311deg, #ff6a55 0%, #ffb330 100%);
//         border-radius: 1.6vw 1.6vw 1.6vw 0px;
//         display: flex;
//         align-items: center;
//         justify-content: center;

//         .X2 {
//           width: 3.2vw;
//         }
//       }
//     }

//     .bgIcon1 {
//       width: 10.8vw;
//       height: 10.8vw;
//     }

//     .bgicon2 {
//       width: 9vw;
//       height: 8.4vw;
//       margin: 0 1.2vw 6vw 1.2vw;
//     }

//     .bgicon3 {
//       width: 16.2vw;
//       height: 13.8vw;
//     }
//   }

//   .bgicon-box2 {
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     position: absolute;
//     top: 36vw;

//     .bgCard2-bg {
//       width: 16vw;
//       margin-top: 2.8vw;
//     }
//   }

//   .bgicon-box3 {
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     position: absolute;
//     top: 36vw;

//     .bgCard3-bg {
//       width: 20vw;
//       margin-top: 0px;
//     }

//     .bgCard3-name {
//       display: flex;
//       justify-content: center;
//       color: #c23d11;
//       font-size: 3.2vw;
//       margin-top: 3vw;

//       .bgCard3-name-left {
//         margin-right: 3.8vw;
//       }
//     }
//   }

//   .btn-have {
//     width: 30vw;
//     height: 8.4vw;
//     line-height: 8.4vw;
//     background: linear-gradient(270deg, #fcbc3c 0%, #ff5f15 100%);
//     box-shadow: inset 0.2vw 0.2vw 1vw 0px rgba(255, 255, 255, 0.4),
//       0px 0.8vw 1.4vw 0px #ffe185;
//     border-radius: 4.2vw;
//     text-align: center;
//     color: #fff;
//     font-size: 3.2vw;
//     position: absolute;
//     bottom: 12vw;
//     left: 50%;
//     transform: translateX(-50%);
//     cursor: pointer;
//   }

//   .jl-tips {
//     position: absolute;
//     bottom: 0px;
//     left: 50%;
//     transform: translateX(-50%);
//     text-align: center;
//     color: #d9d9d9;
//     font-size: 2.8vw;
//   }
//   .jl-tips2 {
//     bottom: -4vw;
//   }
// }
.jl-box {
  position: fixed;
  z-index: 1000001;
  width: 349px;
  height: 400px;
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  .jl-box-card {
    width: 100%;
    height: 100%;
  }

  .identifier {
    position: absolute;
    top: 145px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #c23d11;
    font-size: 16px;
  }

  // background-color: #fff;
  .bgCard1 {
    width: 100%;
    height: 100%;
  }

  .close {
    position: absolute;
    right: -26px;
    top: 23px;
    width: 26px;
    height: 26px;
    cursor: pointer;
  }

  .bgicon-box {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    top: 180px;

    .bgIcon1-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #c23d11;
      font-size: 18px;
      font-weight: bolder;
    }

    .bgIcon3-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #c23d11;
      font-size: 18px;
      font-weight: bolder;
      position: relative;

      .X2-box {
        position: absolute;
        top: -8px;
        right: -15px;
        width: 29px;
        height: 16px;
        background: linear-gradient(311deg, #ff6a55 0%, #ffb330 100%);
        border-radius: 8px 8px 8px 0px;
        display: flex;
        align-items: center;
        justify-content: center;

        .X2 {
          width: 16px;
        }
      }
    }

    .bgIcon1 {
      width: 54px;
      height: 54px;
    }

    .bgicon2 {
      width: 45px;
      height: 42px;
      margin: 0 6px 30px 6px;
    }

    .bgicon3 {
      width: 81px;
      height: 69px;
    }
  }

  .bgicon-box2 {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 180px;

    .bgCard2-bg {
      width: 80px;
      margin-top: 14px;
    }
  }

  .bgicon-box3 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 180px;

    .bgCard3-bg {
      width: 100px;
      margin-top: 0px;
    }

    .bgCard3-name {
      display: flex;
      justify-content: center;
      color: #c23d11;
      font-size: 16px;
      margin-top: 15px;

      .bgCard3-name-left {
        margin-right: 19px;
      }
    }
  }

  .btn-have {
    width: 150px;
    height: 42px;
    line-height: 42px;
    background: linear-gradient(270deg, #fcbc3c 0%, #ff5f15 100%);
    box-shadow: inset 1px 1px 5px 0px rgba(255, 255, 255, 0.4),
      0px 4px 7px 0px #ffe185;
    border-radius: 21px;
    text-align: center;
    color: #fff;
    font-size: 16px;
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }

  .jl-tips {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #d9d9d9;
    font-size: 14px;
  }
  .jl-tips2 {
    bottom: -20px;
  }
}
</style>
